import http from "../../axios";



const state = () => ({
  userLanguage: localStorage.getItem('xx-user-language'),
});

const getters = {
  getUserLanguage(state) {
    return state.userLanguage;
  },
};

const actions = {

};

const mutations = {
  setUserLanguage(state, data) {
    localStorage.setItem('xx-user-language', data)
    state.userLanguage = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
