import http from "../../axios";

const urls = {
  profile: "/portal/",
  confirmation: "/portal/confirmation"
};
const state = () => ({
  userProfile: undefined,
  confirmationStatus: undefined
});

const getters = {
  getUserProfile(state) {
    return state.userProfile;
  },
  getConfirmationStatus(state) {
    return state.confirmationStatus;
  },
};

const actions = {
  async getApiUserProfile({ commit }, payload) {
    try {
      const response = await http.get(urls.profile);
  
      if (response && response.data && response.data.status === 200) {
        commit("setUserProfile", response.data.user);
      }
    } catch (error) {
      console.log(error);
      commit("setUserProfile", undefined);
    }
  },
  async confirmUserPassword({commit}, payload){
      try {
        const response = await http.post(urls.confirmation,payload)
        if(response && response.data.message === 'confirmed'){
          commit("setConfirmation", true)
        }
        if(response && response.data.message === 'Invalid Password'){
          commit("setConfirmation", false)
        }
      } catch (error) {
        console.log(error)
      }
  }
};

const mutations = {
  setUserProfile(state, data) {
    state.userProfile = data;
  },
  setConfirmation(state,data){
    state.confirmationStatus = data
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
