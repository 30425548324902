<template>
<main id="app">
<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <router-view />
</main>
</template>

<script>


export default {
  name: 'App',
  components: {

  }
}
</script>
<style>
@import "./css/general.css";
@import "./css/animations.css";
@import "./css/colors.css";

html {
  height: 100%;
}
body {
  height: 100%;
  margin: 0 
}
#app {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  overflow: hidden;
  height: 100%;
}
</style>

 