import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import axios from "./axios";
//toasts
import Toast, { useToast } from "vue-toastification";
import "vue-toastification/dist/index.css";
//font awesome
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPhone,
  faBars,
  faUserGear,
  faRightFromBracket,
  faSquareUpRight,
  faMicroscope,
  faUsers,
  faTable,
  faLocationArrow,
  faPlus,
  faFileArrowDown,
  faNewspaper,
  faEye,
  faEyeSlash,
  faChartLine,
  faFileInvoiceDollar,
  faEuroSign,
  faFileInvoice,
  faCircleQuestion
} from "@fortawesome/free-solid-svg-icons";
import ErrorService from "../src/services/ErrorService"
library.add(
  faPhone,
  faBars,
  faUserGear,
  faRightFromBracket,
  faSquareUpRight,
  faMicroscope,
  faUsers,
  faTable,
  faLocationArrow,
  faPlus,
  faFileArrowDown,
  faNewspaper,
  faEye,
  faEyeSlash,
  faChartLine,
  faFileInvoiceDollar,
  faEuroSign,
  faFileInvoice,
  faCircleQuestion
);
let toast = useToast();

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("jwt_accessToken");
    if (token) {
      config.headers.common["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalConfig = error.config;

    //case of an error on response
    if (error.response) {
      return ErrorService.authHandler(error, originalConfig);
    }
  }
);
const toastOptions = {};
createApp(App)
  .use(router)
  .use(store)
  .use(Toast, toastOptions)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
