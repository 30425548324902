import { createWebHistory, createRouter } from "vue-router";
const routes = [
  {
    meta: {title:"Willkommen"}, path: "/", name: "home", component: () => import("./path/index")
	},
  {
		meta: {title:"Anmelden"}, path: "/login", name: "login", component: () => import("./path/Login")
	},
  {
		meta: {title:"Registrieren"}, path: "/signup", name: "signup", component: () => import("./path/Signup")
	},
  {
		meta: {title:"Bestätigen"}, path: "/verify", name: "verify", component: () => import("./path/Verify")
	},
  {
		meta: {title:"Passwort vergessen"}, path: "/forgot", name: "forgot", component: () => import("./path/Forgot")
	},
  {
    path: "/reset/:token",
    name: "reset",
    component: () => import("./path/Reset"),
  },
  {
    path: "/portal",
    name: "portal",
    component: () => import("./path/portal/frame"),
    children: [
      {
				meta: {title:"Home"},
        path: "",
        component: () => import("./path/portal/index"),
      },
      {
				meta: {title:"Profil"},
        path: "/portal/profile",
        component: () => import("./path/portal/profile/index"),
      },
      {
				meta: {title:"Kampagnen"},
        path: "/portal/campaign",
        component: () => import("./path/portal/campaign/index"),
      },
      {
				meta: {title:"Exportieren"},
        path: "/portal/campaign/admin/export",
        component: () =>
          import("./path/portal/campaign/admin/export/ExportOverview"),
      },
      {
				meta: {title:"Erstellte Kampagnen"},
        path: "/portal/stagecampaign",
        component: () => import("./path/portal/campaign/stage/index"),
      },
      {
				meta: {title:"Aktuelle Kampagnen"},
        path: "/portal/livecampaigns",
        component: () => import("./path/portal/campaign/live/index"),
      },
      {
				meta: {title:"Kampagne verifizieren"},
        path: "/portal/stagecampaign/:id",
        component: () => import("./path/portal/campaign/stage/single/index"),
      },
      {
				meta: {title:"Expo"},
        path: "/portal/reports/multiscreen",
        component: () => import("./path/portal/reports/multiscreen/index"),
      },
      {
				meta: {title:"Report"},
        path: "/portal/reports/:id",
        component: () => import("./path/portal/reports/single/index"),
      },
      {
				meta: {title:"Conversion Pixel"},
        path: "/portal/conversion/:id",
        component: () => import("./path/portal/campaign/conversion/index"),
      },
      {
				meta: {title:"Report"},
        path: "/portal/reports",
        component: () => import("./path/portal/reports/single/index"),
      },
      {
				meta: {title:"FAQ"},
        path: "/portal/faq",
        component: () => import("./path/portal/Faq"),
      },
      {
				meta: {title:"Kampagne erstellen"},
        path: "/portal/campaign/create",
        component: () => import("./path/portal/campaign/create/newCreate"),
      },
      {
				meta: {title:"Bezahlen"},
        path: "/portal/campaign/pay/:id",
        component: () => import("./path/portal/campaign/pay/newPay"),
      },
      {
				meta: {title:"Kampagne bearbeiten"},
        path: "/portal/campaign/edit/:id",
        component: () => import("./path/portal/campaign/edit/stage/index"),
      },
      {
				meta: {title:"Nach Yahoo exportieren"},
        path: "/portal/campaign/admin/export/yahoo/:id",
        component: () =>
          import("./path/portal/campaign/admin/export/yahoo/index"),
      },
			{
				meta: {title:"Werbemittel erstellen"},
				path: "/portal/publisher/create-wm",
				component: () => import("./path/portal/publisher/createWm/index")
			},
      {
				meta: {title:"Abmelden"},
        path: "/portal/logout",
        component: () => import("./path/portal/logout/index"),
      },
      {
        path: "/portal/advertiser",
        component: () => import("./path/portal/advertiser/account/index"),
      },
      {
        path: "/portal/logs",
        component: () => import("./path/portal/logs/index"),
      }
      ,
      {
        path: "/portal/invoices",
        component: () => import("./path/portal/invoices/index"),
      }
      ,
      {
        path: "/portal/myinvoices",
        component: () => import("./path/portal/invoices/userInvoice"),
      }
      ,
      
      {
        path: "/portal/advertiser/:id",
        component: () => import("./path/portal/advertiser/account/single/index"),
      }
      ,
      {
        meta: {title:"Meine Rechnungen"},
        path: "/portal/bookings",
        component: () => import("./path/portal/advertiser/client/Index"),
      }
    ],
  },
  { path: "/:catchAll(.*)", component: () => import("./path/404/index") },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("jwt_accessToken");
  if (
    !token &&
    to.name !== "login" &&
    to.name !== "signup" &&
    to.name !== "home" &&
    to.name !== "verify" &&
    to.name !== "forgot" &&
    to.name !== "reset"
  ) {
    const originalPath = to.fullPath;
    next({ name: "login", query: { next: originalPath } });
  } else {
    next();
  }
});
router.afterEach((to, from, next) => {
  document.title = to.meta.title || "twiago Portal";
});
export default router;
