import http from "../../axios";

const urls = {
  balance: "/portal/balance",
  userCampaigns: "/portal/campaigns",
  oneCampaign : "/portal/stage_campaign/",
};
let localPayAmount = localStorage.getItem('campaign-payAmount')
const state = () => ({
  balance: undefined,
  hasActiveCampaigns: false,
  payAmount: /* localPayAmount !== null ?  localPayAmount : */ ''
});

const getters = {
  getBalance(state) {
    return state.balance;
  },
  getActiveCampaignsStatus(state) {
    return state.hasActiveCampaigns;
  },
  getPayAmount(state) {
    return state.payAmount
  }
};

const mutations = {
  setBalance(state, data) {
    state.balance = data;
  },
  setActiveCampaignsStatus(state, data) {
    state.hasActiveCampaigns = data;
  },
  setPayAmount(state,data){
    let campaignAmount = parseFloat((data.V_intClickN * data.fltCPCBrutto).toFixed(2))
    let parsedBalance = parseFloat((state.balance).replace(",",".")).toFixed(2)
    if(state.hasActiveCampaigns === false){
        if(parsedBalance > campaignAmount ){
            //localStorage.setItem('campaign-payAmount', 0)
            state.payAmount  = 0
            //return state.payAmount = ()
        }else {

            //localStorage.setItem('campaign-payAmount', parseFloat((campaignAmount - parsedBalance).toFixed(2)))
           // console.log(localStorage.getItem('campaign-payAmount'))
            state.payAmount = parseFloat((campaignAmount - parsedBalance).toFixed(2))
        }
    } else {
      state.payAmount = parseFloat((data.V_intClickN * data.fltCPCBrutto).toFixed(2))
    }
   /*  state.payAmount = data */
  }
};

const actions = {
  async getUserBalance({ commit }, payload) {
    try {
      const response = await http.get(urls.balance);
      if (
        response.data.message === "not allowed" ||
        response.data.message === "not found"
      ) {
        return commit("setBalance", undefined);
      }
      if(
        response.data.message === "empty"
      ) {
        return commit("setBalance", "0,00");
      }
      if (response && response.data && response.data.message === "Success") {
        commit("setBalance", response.data.balance);
      }

    } catch (error) {
      console.log(error);
    }
  },
  async getApiUserCampaigns({ commit }, payload) {
    try {
      const response = await http.get(urls.userCampaigns);
     
      if (response && response.data && response.data.status === 200) {
        for (let i = 0; i < response.data.campaigns.length; i++) {
          if (response.data.campaigns[i].intStatus === "1") {
            commit("setActiveCampaignsStatus", true);
          }
        }
      } else {
        commit("setActiveCampaignsStatus", undefined);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async calculatePayAmount({commit}, payload) {
    try {
        const response = await http.get(urls.oneCampaign + payload);
        if(response.data.message !== 'not allowed' || response.data.message !== 'not found'&& response.data){
            commit("setPayAmount", response.data)
        }
    } catch (error) {
        console.log(error)
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
