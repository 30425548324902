import { createStore } from 'vuex'
import userModule from "./modules/user"
import languageModule from './modules/language';
import campaignModule from "./modules/campaign"
import balanceModule from "./modules/balance"

const store = createStore({
  modules: {
   user: userModule,
   language: languageModule,
   campaign: campaignModule,
   balance : balanceModule
  }
})

export default store;
