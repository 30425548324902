import http from "../axios.js";
import ErrorService from "./ErrorService";
const isDev = process.env.VUE_APP_NODE_ENV == "prod" ? false : true;

class DataService {
  /**@refresh */
  async getRefresh() {
    try {
      const response = await http.get(`${process.env.VUE_APP_NODE_ENV == "prod"
          ? process.env.VUE_APP_URL_PROD
          : process.env.VUE_APP_URL_DEV
        }refresh`);
      return response;
    } catch (error) { }
  }
  /**@User ---------------- */
  /**@User Login */
  async postLogin(data) {
    try {
      const response = await http.post("/login", data);
      const checkedResponse = ErrorService.loginHandler(response);
      return checkedResponse;
    } catch (error) {
      /*  const errorHandler = ErrorService.loginHandler(error);
      return errorHandler; */
    }
  }
  /**@User Signup */
  async postSignUp(data) {
    try {
      const response = await http.post("/signup", data);

      return response;
    } catch (error) {
      console.log(error);
    }
  }
  /**@User forgotPW */
  async postForgot(data) {
    try {
      const response = await http.post("/forgot", data);
      return response;
    } catch (error) {
      /* const errorHandler = ErrorService.checkStatus(error); */
    }
  }
  /**@User ResetPW */
  async postReset(data) {
    try {
      const response = await http.post("/reset", data);
      return response;
    } catch (error) {
      /* const errorHandler = ErrorService.checkStatus(error); */
    }
  }
  /**@User Get User */
  async getPortalUser() {
    try {
      const response = await http.get("/portal/", {
        /* headers: {
          autorization: `Bearer ${reToken}`,
        }, */
      });
      return response;
    } catch (error) {
      const errorHandler = ErrorService.checkStatus(error);
      return errorHandler;
    }
  }
  /** @User update der User mit der V-Model Daten , Haupt Funktion für adspirit konten Verknüpfung */
  async updatePortalUser(data) {
    try {
      const response = await http.post("/portal/", data, {
        headers: {
          //autorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      });
      return response;
    } catch (error) {
      /*     const errorHandler = ErrorService.login(error);
      return errorHandler; */
    }
  }
  /**@User logout */
  async getLogout() {
    try {
      const response = await http.get("/logout", {
        /*headers: {
          autorization: `Bearer ${token}`,
        },*/
      });
      return response;
    } catch (error) {
      /* const errorHandler = ErrorService.checkStatus(error); */
    }
  }
  async getVerify(verifyToken) {
    try {
      const response = await http.get("/verify/" + verifyToken);
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async getLogs(date) {
    try {
      const response = await http.get("/api/logs/" + date, {
        /*headers: {
          autorization: `Bearer ${token}`,
        },*/
      });
      return response;
    } catch (error) {
      /*   const errorHandler = ErrorService.login(error);
      return errorHandler; */
    }
  }
  /**@Adspirit ------------------------------- */
  /**@Adspirit get All Adspirit Active Campaigns */
  async getCampaigns() {
    try {
      const response = await http.get("/api/campaigns", {
        /*headers: {
          autorization: `Bearer ${token}`,
        },*/
      });
      return response;
    } catch (error) {
      /*   const errorHandler = ErrorService.login(error);
      return errorHandler; */
    }
  }
  async getPortalCampaigns() {
    try {
      const response = await http.get("/api/portalCampaigns", {
        /*headers: {
          autorization: `Bearer ${token}`,
        },*/
      });
      return response;
    } catch (error) {
      /*   const errorHandler = ErrorService.login(error);
      return errorHandler; */
    }
  }
  /**@Adspirit get One Campaign */
  async getOneCampaign(kid) {
    try {
      const response = await http.get("/api/campaign/" + kid, {
        /*headers: {
          autorization: `Bearer ${token}`,
        },*/
      });
      return response;
    } catch (error) {
      /* const errorHandler = ErrorService.checkStatus(error);
      return errorHandler; */
    }
  }
  /**@@Adspirit get Creative Count from KID */
  async getCampCreativeCount(kid) {
    try {
      const response = await http.get("/api/creativeCount/" + kid, {
        /*headers: {
          autorization: `Bearer ${token}`,
        },*/
      });
      return response;
    } catch (error) {
      /*  const errorHandler = ErrorService.checkStatus(error); */
    }
  }
  /**@@Adspirit get All Creatives from KID */
  async getCreativesFromKid(kid) {
    try {
      const response = await http.get("/api/creativeFC/" + kid, {
        /*headers: {
          autorization: `Bearer ${token}`,
        },*/
      });
      return response;
    } catch (error) {
      /*   const errorHandler = ErrorService.checkStatus(error); */
    }
  }
  /**@Adspirit Get Campaign rest Click */
  async getCampRestClick(kid) {
    try {
      const response = await http.get("/report/campaignRC/" + kid, {
        /*headers: {
          autorization: `Bearer ${token}`,
        },*/
      });
      return response;
    } catch (error) {
      /*   const errorHandler = ErrorService.checkStatus(error);
      return errorHandler; */
    }
  }
  /**@Adspirit Get Campaign Daily Click */
  async getCampDailyClick(kid) {
    try {
      const response = await http.get("/report/campaignDC/" + kid, {
        /*headers: {
          autorization: `Bearer ${token}`,
        },*/
      });
      return response;
    } catch (error) {
      /*  const errorHandler = ErrorService.checkStatus(error);
      return errorHandler; */
    }
  }
  /**@Adspirit Get Campaign Report by WM */
  async getCampReport(data) {
    try {
      const response = await http.post("/report/campaign/", data, {
        /*headers: {
          autorization: `Bearer ${token}`,
        },*/
      });
      return response;
    } catch (error) {
      /*   const errorHandler = ErrorService.checkStatus(error);
      return errorHandler; */
    }
  }
  /**@Adspirit Get Campaign Report for whole campaign */
  async getCampReportFull(data) {
    try {
      const response = await http.post("/report/campaignFull/", data, {
        /*headers: {
          autorization: `Bearer ${token}`,
        },*/
      });
      return response;
    } catch (error) {
      /*   const errorHandler = ErrorService.checkStatus(error);
      return errorHandler; */
    }
  }
  /**@Adspirit Get Campaign Report */
  async getCreativeReport(data) {
    try {
      const response = await http.post("/report/creative/", data, {
        /*headers: {
            autorization: `Bearer ${token}`,
          },*/
      });
      return response;
    } catch (error) {
      /*   const errorHandler = ErrorService.checkStatus(error);
        return errorHandler; */
    }
  }

  /**@Adspirit Create Campaign */
  async createCampaign(data) {
    try {
      const response = await http.post("/api/campaign/", data, {
        /*headers: {
          autorization: `Bearer ${token}`,
        },*/
      });
      return response;
    } catch (error) {
      /*   const errorHandler = ErrorService.checkStatus(error);
      return errorHandler; */
    }
  }
  async rejectCampaign(data) {
    try {
      const response = await http.post("/api/rejectCampaign/", data, {
        /*headers: {
          autorization: `Bearer ${token}`,
        },*/
      });
      return response;
    } catch (error) {
      /*   const errorHandler = ErrorService.checkStatus(error);
      return errorHandler; */
    }
  }
  /**@Yahoo ------------------ */
  /**@Yahoo get All Yahoo Campaigns */
  async getCampaignsYahoo() {
    try {
      const response = await http.get("/yahoo/campaigns", {
        headers: {
          // autorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      });
      return response;
    } catch (error) {
      const errorHandler = ErrorService.checkStatus(error);
    }
  }
  /**@Yahoo get Api Limit */
  async getApiLimit() {
    try {
      const response = await http.get("/yahoo/limit", {
        /*headers: {
          autorization: `Bearer ${token}`,
        },*/
      });
      return response;
    } catch (error) {
      const errorHandler = ErrorService.checkStatus(error);
    }
  }
  /**@Yahoo create new Yahoo Campaign */
  async createCampaignYahoo(data) {
    data.isDev = isDev;
    try {
      const response = await http.post("/yahoo/campaign", data, {
        headers: {
          //  autorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      });
      return response;
    } catch (error) {
      const errorHandler = ErrorService.yahoo(error);
      return errorHandler;
    }
  }
  /**@Yahoo create new Yahoo Line */
  async createLineYahoo(data) {
    data.isDev = isDev;
    try {
      const response = await http.post("/yahoo/line", data, {
        headers: {
          // autorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      });
      return response;
    } catch (error) {
      const errorHandler = ErrorService.yahoo(error);
      return errorHandler;
    }
  }
  /**@Yahoo create new Yahoo Line Targeting */
  async createTargetingYahoo(data) {
    data.isDev = isDev;
    try {
      const response = await http.post("/yahoo/lineTargeting", data, {
        headers: {
          // autorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      });
      return response;
    } catch (error) {
      const errorHandler = ErrorService.yahoo(error);
      return errorHandler;
    }
  }
  /**@Yahoo create new Yahoo Creative */
  async createCreativeYahoo(data) {
    data.isDev = isDev;
    try {
    } catch (error) {
      const errorHandler = ErrorService.yahoo(error);
    }
  }
  /**@Yahoo create new Yahoo CreativeBulk */
  async createCreativeBulkYahoo(data) {
    try {
      const response = await http.post("/yahoo/creativeBulk", data, {
        headers: {
          // autorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      });
      return response;
    } catch (error) {
      const errorHandler = ErrorService.yahoo(error);
      return errorHandler;
    }
  }
  /**@SBtool ------------------------ */
  /**@SBtool Create Advertiser */
  async createAdvertiser() {
    try {
      const response = await http.get("/api/advertiser/", {
        /*headers: {
          autorization: `Bearer ${token}`,
        },*/
      });
      return response;
    } catch (error) {
      const errorHandler = ErrorService.checkStatus(error);
      return errorHandler;
    }
  }
  /**@SBtool Create Stage Campaign */
  async createStageCampaign(data) {
    try {
      const response = await http.post("/portal/stage_campaign/", data, {
        headers: {
          //'Content-type':'multipart/form-data',
          // autorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  /**@SBtool Create Adspirit Campaign from Stage Campaign */
  async createStageToAdspirit(data) {
    try {
      const response = await http.post("/api/stage_adspirit/", data, {
        headers: {
          //'Content-type':'multipart/form-data',
          // autorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  /**@SBtool Get All Stage Campaigns */
  async getStageCampaigns() {
    try {
      const response = await http.get("/api/stage_campaigns", {
        headers: {
          // autorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      console.log(error);
      /*  const errorHandler = ErrorService.login(error);
      return errorHandler; */
    }
  }
  /**@SBtool Get All Stage Campaigns For User */
  async getUserStageCampaigns() {
    try {
      const response = await http.get("/portal/stage_campaigns", {
        headers: {
          // autorization: `Bearer ${token}`,
        },
      });

      return response;
    } catch (error) {
      console.log(error);
      /*  const errorHandler = ErrorService.login(error);
      return errorHandler; */
    }
  }
  /**@SBtool Get All Stage Campaigns For User */
  async getUserOneStageCampaigns(id) {
    try {
      const response = await http.get("/portal/stage_campaign/" + id, {
        headers: {
          // autorization: `Bearer ${token}`,
        },
      });

      return response;
    } catch (error) {
      console.log(error);
      /*  const errorHandler = ErrorService.login(error);
      return errorHandler; */
    }
  }
  /**@SBtool Get All Stage Rejected Campaigns For User */
  async getUserStageRejectedCampaigns() {
    try {
      const response = await http.get("/portal/stage_rejected_campaigns", {
        headers: {
          // autorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      console.log(error);
      /*  const errorHandler = ErrorService.login(error);
      return errorHandler; */
    }
  }
  /**@SBtool Get One Stage Campaigns */
  async getOneStageCampaign(id) {
    try {
      const response = await http.get("/api/stage_campaigns/" + id, {
        /*headers: {
          autorization: `Bearer ${token}`,
        },*/
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  /**@SBtool Get One Stage Campaigns For User */
  async getUserOneStageCampaign(id) {
    try {
      const response = await http.get("/portal/stage_campaign/" + id, {
        headers: {
          // autorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async getUserCampaigns() {
    try {
      const response = await http.get("/portal/campaigns", {
        headers: {
          // autorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      const errorHandler = ErrorService.checkStatus(error);
      return errorHandler;
    }
  }
  async getUserOneAdsCampaign(id) {
    try {
      const response = await http.get("/portal/campaign/" + id, {
        headers: {
          // autorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      const errorHandler = ErrorService.checkStatus(error);
      return errorHandler;
    }
  }
  async updateRejectedCampaign(id, data) {
    try {
      const response = await http.post(
        "/portal/stage_rejected_campaigns/" + id,
        data,
        {
          /*headers: {
            autorization: `Bearer ${token}`,
          },*/
        }
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  /**@Paypal Add Payment*/
  async addPayment(data) {
    try {
      const response = await http.post("/portal/payment", data, {
        /*headers: {
          autorization: `Bearer ${token}`,
        },*/
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  /**@Action Add Action*/
  async addAction(data) {
    try {
      const response = await http.post("/portal/action", data, {
        /*headers: {
          autorization: `Bearer ${token}`,
        },*/
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async getAllAdvertiserBooking() {
    try {
      const response = await http.get("/api/advertiserBooking", {
        /*headers: {
          autorization: `Bearer ${token}`,
        },*/
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async getOneAdvertiserBooking(id) {
    try {
      const response = await http.get("/api/advertiserBooking/" + id, {
        /*headers: {
          autorization: `Bearer ${token}`,
        },*/
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async breakCampaign(id) {
    try {
      const response = await http.post("/portal/break/" + id, {
        /*headers: {
          autorization: `Bearer ${token}`,
        },*/
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async activateCampaign(id) {
    try {
      const response = await http.post("/portal/activate/" + id, {
        /*headers: {
          autorization: `Bearer ${token}`,
        },*/
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async getConfirmation(data) {
    try {
      const response = await http.post("/portal/confirmation", data ,{
        /*headers: {
          autorization: `Bearer ${token}`,
        },*/
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async getBalance() {
    try {
      const response = await http.get("/portal/balance" ,{
        /*headers: {
          autorization: `Bearer ${token}`,
        },*/
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async getBookings() {
    try {
      const response = await http.get("/portal/bookings" ,{
        /*headers: {
          autorization: `Bearer ${token}`,
        },*/
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
   /**@SBtool Get all invoices */
   async getInvoices() {
    try {
      const response = await http.get("/api/invoices", {
        /*headers: {
          autorization: `Bearer ${token}`,
        },*/
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  /**@SBtool Get all User invoices */
  async getUserInvoices() {
    try {
      const response = await http.get("/portal/invoices", {
        /*headers: {
          autorization: `Bearer ${token}`,
        },*/
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
}

export default new DataService();
