import axios from "../axios";
import DataService from "./DataService";
class ErrorService {
  loginHandler(error) {
    const status = error.data.status;
    let response = "";
    switch (status) {
      case 400:
        response = "Konto noch nicht verfiziert";
        break;
      case 401:
        response = "Email oder Passwort ist falsch";
        break;
      case 403:
        response = "Verboten";
        break;
      case 500:
        response = "Server Error";
        break;
      default:
        response = error;
        break;
    }
    return response;
  }
  signupValidation(response) {
   let errorMessage = "";
   for(let i =0 ; i< response.data.length; i++){
    const field = response.data[i].field;
    const message = response.data[i].message;
    const index = i + 1;
    switch(field){
      case "firstname":
        this.classAdder(field,"validation-error");
        errorMessage += index + ". " + message + "\n"
        break;
      case "lastname":
        this.classAdder(field,"validation-error");
        errorMessage += index + ". " + message + "\n"
        break;
      case "gender":
        this.classAdder(field,"validation-error");
        errorMessage += index + ". " + message + "\n"
        break;
      case "user_type":
        this.classAdder(field,"validation-error");
        errorMessage += index + ". " + message + "\n"
        break;
      case "email":
        this.classAdder(field,"validation-error");
        errorMessage += index + ". " + message + "\n"
        break;
      case "pass":
        this.classAdder(field,"validation-error");
        errorMessage += index + ". " + message + "\n"
        break;
      case "re_pass":
        this.classAdder(field,"validation-error");
        errorMessage += index + ". " + message + "\n"
        break;
      case "company":
        this.classAdder(field,"validation-error");
        errorMessage += index + ". " + message + "\n"
        break;
    }
 
   }
   return errorMessage;
  }
  forgotValidation(response){
    let errorMessage = "";
   for(let i =0 ; i< response.data.length; i++){
    const field = response.data[i].field;
    const message = response.data[i].message;
    const index = i + 1;
    switch(field){
      case "email":
        this.classAdder(field,"validation-error");
        errorMessage += index + ". " + message + "\n"
        break;
    }
   }
   return errorMessage;
  }
  resetValidation(response){
    let errorMessage = "";
   for(let i =0 ; i< response.data.length; i++){
    const field = response.data[i].field;
    const message = response.data[i].message;
    const index = i + 1;
    switch(field){
      case "password":
        this.classAdder(field,"validation-error");
        errorMessage += index + ". " + message + "\n"
        break;
      case "confirmPassword":
        this.classAdder(field,"validation-error");
        errorMessage += index + ". " + message + "\n"
        break;
    }
   }
   return errorMessage;
  }
  updateUserValidation(response){
    let errorMessage = "";
    for(let i=0 ; i < response.data.length; i++){
      const field = response.data[i].field;
      const message = response.data[i].message;
      const index = i + 1;
      switch(field){
        case "firstname":
          this.classAdder(field,"validation-error");
          errorMessage += index + ". " + message + "\n"
          break;
        case "lastname":
          this.classAdder(field,"validation-error");
          errorMessage += index + ". " + message + "\n"
          break;
        case "company":
          this.classAdder(field,"validation-error");
          errorMessage += index + ". " + message + "\n"
          break;
        case "address":
          this.classAdder(field,"validation-error");
          errorMessage += index + ". " + message + "\n"
          break;
        case "plz":
          this.classAdder(field,"validation-error");
          errorMessage += index + ". " + message + "\n"
          break;
        case "city":
          this.classAdder(field,"validation-error");
          errorMessage += index + ". " + message + "\n"
          break;
        case "country":
          this.classAdder(field,"validation-error");
          errorMessage += index + ". " + message + "\n"
          break;
        case "telefon":
          this.classAdder(field,"validation-error");
          errorMessage += index + ". " + message + "\n"
          break;
      }
    }
    return errorMessage;
  }
  yahooHandler(error) {
    const status = error.response.status;
    let response = "";
    switch (status) {
      case 422:
        const errorField =
          error.response.data.errors.validationErrors[0].propertyName;
        if (errorField == "name") {
          response = "Kampagnenname schon vorhanden, bitte umbennen";
        } else if (errorField == "TRAFFIC_LIMIT_PER_MIN") {
          response =
            "Limit von Yahoo ist erreicht, bitte warten Sie kurz moment";
        } else {
          response = errorField;
        }
        break;
    }
    return response;
  }
  checkStatus(error) {
    const status = error.response.data.status;
    let response = "";
    switch (status) {
      case 401:
        response = "Invalid Adspirit Credentiels";
        break;
      case 403:
        response = "Forbidden or Session ended";
        break;
      case 500:
        response = "Server Error";
        break;
    }
    return response;
  }
  inputValidation(response, bookComponent) {
    let errorMessage = "";
    bookComponent.errors = [];
    for (let i = 0; i < response.data.length; i++) {
      switch (response.data[i].field) {
        case "strName":
          bookComponent.errors = [...bookComponent.errors, 0];
          break;
        case "daStart":
          bookComponent.errors = [...bookComponent.errors, 2];
          break;
        case "daEnde":
          bookComponent.errors = [...bookComponent.errors, 2];
          break;
        case "fltCPCBrutto":
          bookComponent.errors = [...bookComponent.errors, 2];
          break;
        case "V_intClickN":
          bookComponent.errors = [...bookComponent.errors, 2];
          break;
        case "creatives[0].strName":
        case "creatives[0].title":
        case "creatives[0].text":
        case "creatives[0].link":
        case "creatives[0].strURL":
        case "creatives[1].strName":
        case "creatives[1].title":
        case "creatives[1].text":
        case "creatives[1].link":
        case "creatives[1].strURL":
        case "creatives[2].strName":
        case "creatives[2].title":
        case "creatives[2].text":
        case "creatives[2].link":
        case "creatives[2].strURL":
          bookComponent.errors = [...bookComponent.errors, 1];
          break;
      }
      document
        .getElementById(response.data[i].field)
        .classList.add("validation-error");
      errorMessage += i + 1 + ". " + response.data[i].message + "\n";
    }
    return errorMessage;
  }
  classRemover(className) {
    const validationFields = document.querySelectorAll(`.${className}`);
    validationFields.forEach((field) => {
      field.classList.remove(`${className}`);
    });
  }
  classAdder(element,className){
    document.getElementById(element).classList.add(className);
  }
  async authHandler(error, originalConfig) {
    if (
      error.response.status === 403 &&
      error.response.data.message === "jwt_expired" &&
      originalConfig.url !==
      `${process.env.VUE_APP_NODE_ENV == "prod"
        ? process.env.VUE_APP_URL_PROD
        : process.env.VUE_APP_URL_DEV
      }refresh`
    ) {
      //call refresh path for a new jwt token
      let refresh = await DataService.getRefresh();
      //if call to refresh path is successful
      if (
        refresh.data.message === "access granted" &&
        refresh.data.status == 200 &&
        refresh.data.token
      ) {

        //failed request should be rehandeld here
        localStorage.setItem("jwt_accessToken", refresh.data.token);

        const retryOriginalRequest = new Promise((resolve) => {
          originalConfig.headers["Authorization"] = `Bearer ${refresh.data.token}`;
          resolve(axios(originalConfig))
        })
        return retryOriginalRequest
        //window.location.reload();
      } else {
        //Refresh server answers that refresh token saved in cookie itself is expired
        if (
          refresh.data.status === 403 &&
          refresh.data.message === "Session Expired"
        ) {
          localStorage.clear();
          window.location.href = "/login?note=expiredSession";
        }
        //Refresh server answers that refresh token saved in cookie is invalid for some reason
        if (
          refresh.data.status === 403 &&
          refresh.data.message === "Invalid Token"
        ) {
          localStorage.clear();
          window.location.href = "/login?note=expiredSession";
        }
        if (
          refresh.data.status === 403 &&
          refresh.data.message === "No RefToken"
        ) {
          localStorage.clear();
          window.location.href = "/login?note=expiredSession";
        }
      }
    } else if (
      //Error error is jwt token invalid
      error.response.status === 403 &&
      error.response.data.message === "Invalid Token" &&
      originalConfig.url !==
      `${process.env.VUE_APP_NODE_ENV == "prod"
        ? process.env.VUE_APP_URL_PROD
        : process.env.VUE_APP_URL_DEV
      }refresh`
    ) {
      localStorage.clear();
      window.location.href = "/login?note=expiredSession";
    }
    //validation error
    else if (error.response.status === 422) {
      return error.response;
    }
    //any other error TODO: maybe show error occured without redirection?
    else {
      return error.response
    }
  }
  resendData(originalRequest) {
    if (originalRequest.method === "post") {

    }
  }
}
export default new ErrorService();
