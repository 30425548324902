import http from "../../axios"

const urls = {
    oneCampaign : "/portal/stage_campaign/",
    createCampaign : "/portal/stage_campaign",
    userCampaigns: "/portal/campaigns",
    payment:"/portal/payment",
    balancePayment:"/portal/balancepayment"
}

const state = () => ({
    oneCampaign : undefined,
    createCampaignStatus: false,
    createdCampaignID:undefined,
    campaignPixel: localStorage.getItem('pixel') === null ? undefined : localStorage.getItem('pixel'),
    userCampaigns: undefined,
    finalCampaignPrice: undefined,
    paymentStatus:false,
    balancePayment:false,
})

const getters = {
    getOneCampaign(state) {
        return state.oneCampaign
    },
    getCreationStatus(state){
        return state.createCampaignStatus
    },
    getCreatedCampaignID(state){
        return state.createdCampaignID
    },
    getCampaignPixel(state){
        return state.campaignPixel
    },
    getUserCampaigns(state){
        return state.userCampaigns
    },
    getFinalCampaignPrice(state){
        return state.finalCampaignPrice
    },
    getPaymentStatus(state){
        return state.paymentStatus
    },
    getBalancePayment(state){
        return state.balancePayment
    }
}

const mutations = {
    setOneCampaign(state,data){
        state.oneCampaign = data
    },
    setCreateCampaignStatus(state,data){
        state.createCampaignStatus = data
    },
    setCreatedCampaignID(state,data){
        state.createdCampaignID = data
    },
    setCampaignPixel(state,data){
        state.campaignPixel = data
    },
    setUserCampaigns(state, data){
        state.userCampaigns  = data
    },
    setFinalCampaignPrice(state, data){
        state.finalCampaignPrice  = data
    },
    setPaymentStatus(state, data){
         state.paymentStatus = data
    },
    setBalancePayment(state, data){
         state.balancePayment = data
    }
}


const actions = {
    async getOneStageCampaign({commit}, payload){
        try {
          const response = await http.get(urls.oneCampaign + payload)
          if(response.data.message === 'not allowed' || response.data.message === 'not found'){
            return commit("setOneCampaign", undefined)
          }
          if(response && response.data)  {
            commit("setOneCampaign", response.data)
          }
            
        } catch (error) {
            console.log(error)
        }
    },
    async createOneStageCampaign({commit}, payload){
        try {
            const response = await http.post(urls.createCampaign, payload)
            if(response && response.data && response.data.status === 200)  {
              commit("setCreateCampaignStatus", true)
              commit("setCreatedCampaignID", response.data.data.campaignID),
              commit("setOneCampaign", response.data.data)
            }
              
        } catch (error) {
            console.log(error)
        }
    },
    async createCampaignPixel({commit}, payload){
        try {
            const response = await http.post("/portal/action", payload);
            if(response && response.data && response.data.action !== undefined){
                commit("setCampaignPixel", response.data.script)
            }
        } catch (error) {
            console.log(error)
        }
    },
    async getApiUserCampaigns({commit}, payload){
        try {
            const response = await http.get(urls.userCampaigns)
            if(response && response.data && response.data.status === 200){
                commit("setUserCampaigns", response.data.campaigns)
            }
        } catch (error) {
            console.log(error)
        }
    },
    addFinalCampaignPrice({commit},payload){
        commit("setFinalCampaignPrice", payload)
    },
    async addPayment({commit}, payload){
        try {
            const response = await http.post(urls.payment, payload);
            if(response && response.data){
                commit("setPaymentStatus", true)
            }
        } catch (error) {
            console.log(error)
        }
    },
    async addBalancePayment({commit}, payload){
        try {
            const response  = await http.post(urls.balancePayment, payload)
            if(response && response.data && response.data.message === 'PAYMENT ADDED'){
                commit("setBalancePayment", true)
            }
        } catch (error) {
            console.log(error)
        }
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };